@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: 'Inter', serif;
    font-size: rfs-fluid-value(22px);
  }
}

#root {
  @apply flex flex-col w-screen min-h-screen;
  header, footer {
    @apply flex-grow-0 flex-shrink-0;
  }
  header {
    position: sticky;
    top:0
  }
  main {
    @apply flex-1 flex flex-col
  }
};

@import "scss/componenets/buttons";
