%button {
  @apply relative inline-flex items-center rounded-md px-3 py-2 text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 transition-colors shadow border disabled:cursor-alias;
  &.rounded {
    @apply rounded-full aspect-square p-2;
  }
}

.btn {
  @extend %button;
}

.btn-primary {
  @extend .btn;
  @apply bg-sky-600 hover:bg-sky-700 focus-visible:outline-sky-600 text-white border-sky-600 hover:border-sky-700;
}

.btn-success {
  @extend .btn;
  @apply bg-green-600 hover:bg-green-700 focus-visible:outline-green-600 text-white border-green-600 hover:border-green-700;
}


.btn-primary-outline {
  @extend .btn;
  @apply text-sky-600 hover:bg-sky-700 focus-visible:outline-sky-600 border-sky-600 hover:border-sky-700 hover:text-white;
}

.btn-white {
  @extend .btn;
  @apply bg-white hover:bg-gray-100 focus-visible:outline-white text-gray-700 border-white hover:border-gray-100;
}


.btn-white-outline {
  @extend .btn;
  @apply text-white hover:bg-white hover:text-gray-700 focus-visible:outline-sky-600 border-white hover:border-white;
}

.btn-kebab {
  @extend .btn;
  @apply text-gray-700 hover:text-gray-500 focus-visible:outline-gray-400 border-white shadow-none hover:border-gray-300;
}
